<template>
  <el-container>
    <div class="loading-logo" v-if="loading">
      <div>
        <h3>加载中，请耐心等待……</h3>
      </div>
    </div>
    <div class="w-100 warpper" v-else>
      <div class="banner" :style="{ 'background-image': `url(${require('../../../../assets/images/common/top_banner_' + theme_color + '.png')})` }">
        <h1>{{ title }}报告</h1>
        <p>
          <span v-if="subtitle">{{ subtitle }}</span>
        </p>
      </div>
      <div class="btn_tab" id="emotion-tab">
        <div class="fxbg">
          <div class="warpper">
            <div class="flex-container">
              <a data-href="#qggs" class="selected cur-p" @click="switchTab(0, true)">方案情感概述</a>
              <a data-href="#lyfx" class="cur-p" @click="switchTab(1, true)">总体信息情感媒体来源分析</a>
              <a data-href="#qsfx" class="cur-p" @click="switchTab(2, true)">总体信息情感声量趋势分析</a>
              <a data-href="#fmxxpx" class="cur-p" @click="switchTab(3, true)">负面信息剖析</a>
            </div>
          </div>
        </div>
      </div>
      <div class="fxbg pt_0">
        <!-- 方案情感概述 -->
        <a name="qggs" id="qggs"></a>
        <div class="warpper">
          <el-card class="box-card">
            <h3 class="mod-title">方案情感概述
              <el-tooltip class="item" effect="dark" placement="bottom">
                <div slot="content">分析时间段内监测信息的情感，得出<br>事件的整体情感概况</div>
                <i class="iconfont icon-tishi"></i>
              </el-tooltip>
            </h3>
            <div class="box-shadow"></div>
            <div class="pad-lr-20">
              <p :class="row.keyword.length > 1 ? 'paragraph' : 'paragraph lh-30'" v-for="(row, index) in data.summary" :key="index">
                <strong v-if="row.keyword">{{ row.keyword }}：</strong>
                <span v-if="!isArray(row.text)">{{ row.text }}</span>
                <span class="text-tag" v-for="(tag, index) in row.text" :key="index" v-else>{{ tag }}</span>
              </p>
            </div>
          </el-card>
        </div>
        <!-- 总体信息情感媒体来源分析 -->
        <a name="lyfx" id="lyfx"></a>
        <div>
          <div class="warpper pt-20">
            <el-card class="box-card">
              <h3 class="mod-title">总体信息情感媒体来源分析
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <div slot="content">综合分析各媒体发布信息的情感分布后，<br>得出的结论</div>
                  <i class="iconfont icon-tishi"></i>
                </el-tooltip>
              </h3>
              <div class="box-shadow"></div>
              <div class="pad-lr-20">
                <p class="paragraph" v-for="(row, index) in data.summary_em_dis" :key="index">
                  <strong v-if="row.keyword">{{ row.keyword }}：</strong>
                  <span v-if="!isArray(row.text)">{{ row.text }}</span>
                  <span class="text-tag" v-for="(tag, index) in row.text" :key="index" v-else>{{ tag }}</span>
                </p>
              </div>
            </el-card>
          </div>
          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">总情绪占比
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">时间段内，正负面信息分别占总信息<br>数量的比重</div>
                      <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty hide">
                      <div class="img"></div>
                    </div>
                    <div class="h-250" id="em_por"></div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">各媒体类型情绪分布
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">时间段内，各媒体发布的正负面信息分别<br>占该媒体发布的总信息数量的比重</div>
                      <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data['em_dis_for_cat'].data.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="h-250" id="em_dis_for_cat" v-show="data['em_dis_for_cat'].data.length >= 1"></div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">网媒类型情绪分布
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">时间段内，各网媒发布的正负面信息分别<br>占该网媒发布的总信息数量的比重</div>
                      <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data['em_dis_for_web_media'].data.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="h-250" id="em_dis_for_web_media" v-show="data['em_dis_for_web_media'].data.length >= 1"></div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">微博类型情绪分布
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">时间段内，微博原发或转发的正负面<br>信息分别占微博原发或转发的总信息<br>数量的比重</div>
                      <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data['em_dis_for_weibo_type'].data.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="h-250" id="em_dis_for_weibo_type" v-show="data['em_dis_for_weibo_type'].data.length >= 1"></div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">微博用户类型情绪分布
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">时间段内，各类型微博用户发布的<br>正负面信息分别占该类型微博用户<br>发布的总信息数量的比重</div>
                      <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data['em_dis_for_weibo_user'].data.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="h-250" id="em_dis_for_weibo_user" v-show="data['em_dis_for_weibo_user'].data.length >= 1"></div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">整体信息情感日历
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">时间段内，相关信息情感分布随时间<br>变化的趋势</div>
                      <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data['em_por_date'].data.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="qgrl h-250">
                      <div class="left" v-show="calendarMonth.left.month">
                        <i class="iconfont icon-zuojiantou" @click="calendarChart(calendarMonth.left, 'em_por_date')"></i><br>
                        <span>{{ calendarMonth.left.month }}</span>
                      </div>
                      <div class="h-250 qgrl-chart" id="em_por_date" v-show="data['em_por_date'].data.length >= 1"></div>
                      <div class="right" v-show="calendarMonth.right.month">
                        <i class="iconfont icon-youjiantou" @click="calendarChart(calendarMonth.right, 'em_por_date')"></i><br>
                        <span>{{ calendarMonth.right.month }}</span>
                      </div>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">所有媒体情绪分布图
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">时间段内，所有媒体发布正面和负面<br>信息数量的整体分布</div>
                      <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data['em_dis_for_media'].data.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="h-250 w-100" id="em_dis_for_media" v-show="data['em_dis_for_media'].data.length >= 1"></div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">所有媒体情绪分布占比图
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">时间段内，所有媒体发布正面和负面<br>信息占比的整体分布</div>
                      <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data['em_dis_for_media_por'].data.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="w-100 h-250" id="em_dis_for_media_por" v-show="data['em_dis_for_media_por'].data.length >= 1"></div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
        </div>
        <!-- 总体信息情感声量趋势分析 -->
        <a name="qsfx" id="qsfx"></a>
        <div>
          <div class="warpper pt-20">
            <el-card class="box-card">
              <h3 class="mod-title">总体信息情感声量趋势分析
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <div slot="content">综合分析监测信息的情感走势后，<br>得出的结论</div>
                  <i class="iconfont icon-tishi"></i>
                </el-tooltip>
              </h3>
              <div class="box-shadow"></div>
              <div class="pad-lr-20">
                <p class="paragraph" v-for="(row, index) in data.summary_em_tre" :key="index">
                  <strong v-if="row.keyword">{{ row.keyword }}：</strong>
                  <span v-if="!isArray(row.text)">{{ row.text }}</span>
                  <span class="text-tag" v-for="(tag, index) in row.text" :key="index" v-else>{{ tag }}</span>
                </p>
              </div>
            </el-card>
          </div>
          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">总体信息情感走势
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">时间段内，总的正面和负面信息数量<br>随时间变化的趋势</div>
                      <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data['em_tre'].keys.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="w-100 h-250" id="em_tre" v-show="data['em_tre'].keys.length >= 1"></div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">网媒信息情感走势
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">时间段内，网媒发布的正面和负面<br>信息数量随时间变化的趋势</div>
                      <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data['em_tre_for_web_media'].keys.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="w-100 h-250" id="em_tre_for_web_media" v-show="data['em_tre_for_web_media'].keys.length >= 1"></div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">微博信息情感走势
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">时间段内，微博发布的正面和负面<br>信息数量随时间变化的趋势</div>
                      <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data['em_tre_for_weibo'].keys.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="w-100 h-250" id="em_tre_for_weibo" v-show="data['em_tre_for_weibo'].keys.length >= 1"></div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">微信信息情感走势
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">时间段内，微信发布的正面和负面<br>信息数量随时间变化的趋势</div>
                      <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data['em_tre_for_weixin'].keys.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="w-100 h-250" id="em_tre_for_weixin" v-show="data['em_tre_for_weixin'].keys.length >= 1"></div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20" style="display: none;">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">论坛情感走势
                    <el-tooltip class="item" effect="dark" placement="bottom">
                        <div slot="content">综合分析负面信息的走势后，得出<br>的结论</div>
                        <i class="iconfont icon-tishi"></i>
                      </el-tooltip>
                  </h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty">
                      <div class="img"></div>
                    </div>
                    <div style="height:100px;background:red;display: none;"></div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">其他信息情感走势
                    <el-tooltip class="item" effect="dark" placement="bottom">
                        <div slot="content">对时间段内监测到的行业信息按<br>重要度计算，展示前10条重点信息</div>
                        <i class="iconfont icon-tishi"></i>
                      </el-tooltip>
                  </h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty">
                      <div class="img"></div>
                    </div>
                    <div style="height:100px;background:red;display: none;"></div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
        </div>
        <!-- 总体信息地区情感分布 删除 -->
        <a name="qgfb" id="qgfb"></a>
        <div style="display: none;">
          <div class="warpper pt-20">
            <el-card class="box-card">
              <h3 class="mod-title">总体信息地区情感分布
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <div slot="content">对时间段内监测到的行业信息按<br>重要度计算，展示前10条重点信息</div>
                  <i class="iconfont icon-tishi"></i>
                </el-tooltip>
              </h3>
              <div class="box-shadow"></div>
              <div class="pad-lr-20">
                <p class="paragraph">从各地区的情感走势来看，[地区]的负面信息总量最多，[地区]的正面信息总量最多。[地区]的负面信息占比最高，[地区]的正面信息占比最高。</p>
                <p class="paragraph">而随着时间走势来看，信息总量排名前10名的地区中，[地区1、地区2...没有地区是]有负面信息发布时间集中的趋势，[地区1、地区2...没有地区是]有正面信息发布时间集中的趋势。</p>
                <p class="paragraph">负面信息高峰点发生于[地区][时间]，正面信息高峰点发生于[地区][时间]。</p>
              </div>
            </el-card>
          </div>
          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">总体信息地区情感分布
                    <el-tooltip class="item" effect="dark" placement="bottom">
                        <div slot="content">对时间段内监测到的行业信息按<br>重要度计算，展示前10条重点信息</div>
                        <i class="iconfont icon-tishi"></i>
                      </el-tooltip>
                  </h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty">
                      <div class="img"></div>
                    </div>
                    <div style="height:100px;background:red;display: none;"></div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">总体信息地区情感占比分布
                    <el-tooltip class="item" effect="dark" placement="bottom">
                        <div slot="content">对时间段内监测到的行业信息按<br>重要度计算，展示前10条重点信息</div>
                        <i class="iconfont icon-tishi"></i>
                      </el-tooltip>
                  </h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty">
                      <div class="img"></div>
                    </div>
                    <div style="height:100px;background:red;display: none;"></div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20">
            <el-card class="box-card">
              <h3 class="mod-title">总体信息地区情感趋势
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <div slot="content">对时间段内监测到的行业信息按<br>重要度计算，展示前10条重点信息</div>
                  <i class="iconfont icon-tishi"></i>
                </el-tooltip>
              </h3>
              <div class="pad-lr-20">
                <div class="echarts_empty">
                  <div class="img"></div>
                </div>
                <div style="height:100px;background:red;display: none;"></div>
              </div>
            </el-card>
          </div>
        </div>
        <!-- 负面信息剖析 -->
        <a name="fmxxpx" id="fmxxpx"></a>
        <div>
          <div class="warpper pt-20">
            <el-card class="box-card">
              <h3 class="mod-title">负面信息剖析
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <div slot="content">综合分析负面信息的走势后，得出<br>的结论</div>
                  <i class="iconfont icon-tishi"></i>
                </el-tooltip>
              </h3>
              <div class="box-shadow"></div>
              <div class="pad-lr-20">
                <p class="paragraph">{{ data.summary_em_neg.length >= 1 ? data.summary_em_neg[0].text : "" }}</p>
              </div>
            </el-card>
          </div>
          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">负面情绪媒体类型分布
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">时间段内，各媒体发布的负面信息<br>占总负面信息数量的比重</div>
                      <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data['neg_dis_for_cat'].data.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="h-250" id="neg_dis_for_cat" v-show="data['neg_dis_for_cat'].data.length >= 1"></div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">负面情绪网媒类型分布
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">时间段内，各网媒发布的负面信息<br>占网媒发布的负面信息数量的比重</div>
                      <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data['neg_dis_for_web_media'].data.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="h-250" id="neg_dis_for_web_media" v-show="data['neg_dis_for_web_media'].data.length >= 1"></div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">负面情绪微博类型分布
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">时间段内，微博原发或转发的负面信息<br>占微博发布的负面信息数量的比重</div>
                      <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data['neg_dis_for_weibo_type'].data.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="h-250" id="neg_dis_for_weibo_type" v-show="data['neg_dis_for_weibo_type'].data.length >= 1"></div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">负面情绪微博用户类型分布
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">时间段内，各类型微博用户发布的负面<br>信息占微博发布的负面信息数量的比重</div>
                      <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data['neg_dis_for_weibo_user'].data.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="h-250" id="neg_dis_for_weibo_user" v-show="data['neg_dis_for_weibo_user'].data.length >= 1"></div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">负面信息总量<span class="red">Top10</span>媒体
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">时间段内，发布的负面信息数量最<br>多的10个媒体及其发布信息数</div>
                      <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data.neg_num_top.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="mod-list progress_red progress_no_bg h-386" v-show="data.neg_num_top.length >= 1">
                      <ul>
                        <li>
                          <h3><span class="num-tit">序号</span><span class="mod-list-title1">媒体</span><span class="tag f-r">条数</span></h3>
                        </li>
                        <li v-for="(data, index) in data.neg_num_top" :key="index">
                          <span class="num bg-555" v-if="index == 0">1</span>
                          <span class="num bg-689" v-if="index == 1">2</span>
                          <span class="num bg-8ea" v-if="index == 2">3</span>
                          <span class="num" v-if="index >= 3">{{ index + 1 }}</span>
                          <a class="mod-list-title1" :title="data.name">{{ data.name }}</a>
                          <el-progress :text-inside="true" :stroke-width="14" :percentage="data.rate" :show-text="false"></el-progress>
                          <span class="tag">{{ data.total }}条</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">负面信息占比<span class="red">Top10</span>媒体
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">时间段内，负面信息占比最高的10个<br>媒体及其负面信息占比</div>
                      <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data.neg_por_top.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="mod-list progress_red h-386" v-show="data.neg_por_top.length >= 1">
                      <ul>
                        <li>
                          <h3><span class="num-tit">序号</span><span class="mod-list-title1">媒体</span><span class="tag f-r">占比</span></h3>
                        </li>
                        <li v-for="(data, index) in data.neg_por_top" :key="index">
                          <span class="num bg-555" v-if="index == 0">1</span>
                          <span class="num bg-689" v-if="index == 1">2</span>
                          <span class="num bg-8ea" v-if="index == 2">3</span>
                          <span class="num" v-if="index >= 3">{{ index + 1 }}</span>
                          <a class="mod-list-title1":title="data.name">{{ data.name }}</a>
                          <el-progress :text-inside="true" :stroke-width="14" :percentage="data.percent" :show-text="false"></el-progress>
                          <span class="tag">{{ data.percent }}%</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20">
            <el-card class="box-card">
              <h3 class="mod-title">总体负面信息总体趋势
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <div slot="content">时间段内，总的负面信息随时间变化<br>的趋势</div>
                  <i class="iconfont icon-tishi"></i>
                </el-tooltip>
              </h3>
              <div class="pad-lr-20">
                <div class="echarts_empty" v-show="data['neg_tre_date'].data.length < 1">
                  <div class="img"></div>
                </div>
                <div class="w-100 h-550" id="neg_tre_date" v-show="data['neg_tre_date'].data.length >= 1"></div>
              </div>
            </el-card>
          </div>
          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card ov-v">
                  <h3 class="mod-title">总体负面信息情感走势
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">时间段内，各媒体发布负面信息数量<br>随时间变化的趋势</div>
                      <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data['neg_tre'].data.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="w-100 h-250" id="neg_tre" v-show="data['neg_tre'].data.length >= 1"></div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">网媒负面信息情感走势
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">时间段内，各网媒发布负面信息数量<br>随时间变化的趋势</div>
                      <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data['neg_tre_for_web_media'].data.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="w-100 h-250" id="neg_tre_for_web_media" v-show="data['neg_tre_for_web_media'].data.length >= 1"></div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card">
                  <h3 class="mod-title">微博负面情感走势
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">时间段内，微博原发或转发的负面<br>信息数量随时间变化的趋势</div>
                      <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="!data['neg_tre_for_weibo_type'].keys">
                      <div class="img"></div>
                    </div>
                    <div class="w-100 h-250" id="neg_tre_for_weibo_type" v-show="data['neg_tre_for_weibo_type'].keys"></div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card ov-v">
                  <h3 class="mod-title">微博用戶负面情感走势
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">时间段内，各类型微博用户发布的<br>负面信息数量随时间变化的趋势</div>
                      <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data['neg_tre_for_weibo_user'].data.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="w-100 h-250" id="neg_tre_for_weibo_user" v-show="data['neg_tre_for_weibo_user'].data.length >= 1"></div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card fmxxnrgd">
                  <h3 class="mod-title">负面信息内容观点
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">时间段内，发布次数最多的10个<br>负面观点，及其条数和数量占比</div>
                      <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data.neg_op.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="mod-list progress_red progress_no_bg h-366" v-show="data.neg_op.length >= 1">
                      <ul>
                        <li>
                          <h3><span class="num-tit">序号</span><span class="mod-list-title1">观点</span><span class="tag">占比</span><span class="tag">条数</span></h3>
                        </li>
                        <li v-for="(data, index) in data.neg_op" :key="index">
                          <span class="num bg-555" v-if="index == 0">1</span>
                          <span class="num bg-689" v-if="index == 1">2</span>
                          <span class="num bg-8ea" v-if="index == 2">3</span>
                          <span class="num" v-if="index >= 3">{{ index + 1 }}</span>
                          <a class="mod-list-title1" :href="data.source_url" target="_blank" :title="data.view">{{ data.view }}</a>
                          <span class="tag">{{ data.rate }}%</span>
                          <span class="tag">{{ data.total }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card echarts-box">
                  <h3 class="mod-title">全部负面信息热门关键词
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">对负面信息进行分词及重要关键词词频<br>统计，展示词频最高的50个重要关键词</div>
                      <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data.neg_key.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="w-100 h-100 ov-h mar-t-30 flex" v-show="data.neg_key.length >= 1">
                      <div class="mod-list">
                        <ul>
                          <li v-for="(data, index) in data.neg_key" :key="index" v-if="index < 10">
                            <span class="num bg-555" v-if="index == 0">1</span>
                            <span class="num bg-689" v-if="index == 1">2</span>
                            <span class="num bg-8ea" v-if="index == 2">3</span>
                            <span class="num" v-if="index >= 3">{{ index + 1 }}</span>
                            <a class="title" :title="data.keyword">{{ data.keyword }}</a>
                          </li>
                        </ul>
                      </div>
                      <div class="gjcy mod-echarts-right h-350" id="neg_key"></div>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20">
            <!-- 不是事件 -->
            <el-card class="box-card" v-if="!data['pos_vs_neg'].isIncubation">
              <h3 class="mod-title">负面信息观点趋势变化
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <div slot="content">根据事件走势，展示关键时间点最重要<br>的3条负面信息</div>
                  <i class="iconfont icon-tishi"></i>
                </el-tooltip>
              </h3>
              <div class="pad-lr-20">
                <div class="echarts_empty" v-show="data.neg_op_tre.keys.length < 1">
                  <div class="img"></div>
                </div>
                <!-- 时间轴 -->
                <div class="demo" v-show="data.neg_op_tre.keys.length >= 1">
                  <div class="main-timeline">
                    <div class="timeline" v-for="(time, index) in data.neg_op_tre.keys" :key="index">
                      <div class="timeline-content">
                        <div class="circle">
                          <div class="mod-list">
                            <ul>
                              <li v-for="(data, sub_index) in data.neg_op_tre.data[index]" :key="sub_index" v-if="sub_index < 3">
                                <span class="num bg-555" v-if="sub_index == 0">1</span>
                                <span class="num bg-689" v-if="sub_index == 1">2</span>
                                <span class="num bg-8ea" v-if="sub_index == 2">3</span>
                                <a class="title" :href="data.source_url" target="_blank" :title="data.title">{{ data.title }}</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="cont">
                          <span class="year">{{ time }}</span>
                          <div class="icon"><span></span></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-card>
            <!-- 是事件 -->
            <el-card class="box-card" v-else>
              <h3 class="mod-title">正负观点趋势碰撞
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <div slot="content">根据事件走势，对比关键时间点的正负面<br>观点得分，并展示正负面观点内容</div>
                  <i class="iconfont icon-tishi"></i>
                </el-tooltip>
              </h3>
              <div class="pad-lr-20">
                <div class="w-100 h-400" id="pos_vs_neg_trend">
                </div>
                <div class="echarts_empty hide">
                  <div class="img"></div>
                </div>
                <div class="pk1" v-if="data['pos_vs_neg'].pos_weight > data['pos_vs_neg'].neg_weight && data['pos_vs_neg'].pos_neg_diff > 10">
                  <p class="zm">重要评分：{{ data['pos_vs_neg'].pos_weight }}</p>
                  <p class="fm">重要评分：{{ data['pos_vs_neg'].neg_weight }}</p>
                </div>
                <div class="pk2" v-if="data['pos_vs_neg'].neg_weight > data['pos_vs_neg'].pos_weight && data['pos_vs_neg'].pos_neg_diff > 10">
                  <p class="zm">重要评分：{{ data['pos_vs_neg'].pos_weight }}</p>
                  <p class="fm">重要评分：{{ data['pos_vs_neg'].neg_weight }}</p>
                </div>
                <div class="pk3" v-if="data['pos_vs_neg'].pos_neg_diff <= 10">
                  <p class="zm">重要评分：{{ data['pos_vs_neg'].pos_weight }}</p>
                  <p class="fm">重要评分：{{ data['pos_vs_neg'].neg_weight }}</p>
                </div>
                <!-- 时间轴 -->
                <div class="demo2">
                  <div class="main-timeline2" v-for="(row, index) in data['pos_vs_neg'].timeline" :key="index">
                    <div class="item" v-if="row.type === 'point'">
                      <div class="timeline">
                        <div class="timeline-content">
                          <div class="circle">
                            <div class="timeline_title">
                              <img src="/static/img/sheng_h.svg" alt="胜" class="img" width="25" v-if="row.weight_neg < row.weight_pos"/>
                              <h4><a :href="row.article_pos.source_url" target="_blank">{{ row.article_pos.title }}</a></h4>
                              <p><span>相关报导数：{{ row.article_pos.total }}条</span><span>信息占比：{{ row.article_pos.percent }}%</span></p>
                              <dl>
                                <dd v-for="(media, index) in row.article_pos.medias" :key="index">
                                  <a>{{ media }}</a><span class="bd">|</span>
                                </dd>
                              </dl>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="cont">
                        <span class="year">{{ row.date }}</span>
                        <div class="point">
                          <p class="start" v-for="(point, index) in row.points" :key="index">
                            <img :src="findPointImg(point)" :alt="point" width="18" type="image/svg+xml" />
                            <span>{{ point }}</span>
                          </p>
                        </div>
                      </div>
                      <div class="timeline">
                        <div class="timeline-content">
                          <div class="circle">
                            <div class="timeline_title">
                              <img src="/static/img/sheng_l.svg" alt="胜" class="img" width="25" v-if="row.weight_neg > row.weight_pos"/>
                              <h4><a :href="row.article_neg.source_url" target="_blank">{{ row.article_neg.title }}</a></h4>
                              <p><span>相关报导数：{{ row.article_neg.total }}条</span><span>信息占比：{{ row.article_neg.percent }}%</span></p>
                              <dl>
                                <dd v-for="(media, index) in row.article_neg.medias" :key="index">
                                  <a>{{ media }}</a><span class="bd">|</span>
                                </dd>
                              </dl>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="item item_list" v-else>
                      <div class="timeline">
                        <div class="timeline-content">
                          <div class="circle">
                            <div class="mod-list">
                              <img src="/static/img/sheng_h.svg" alt="胜" class="img" width="25" v-if="row.weight_neg < row.weight_pos" />
                              <ul>
                                <li v-for="(row, index) in row.articles_pos" :key="index">
                                  <span class="top1" v-if="index === 0">1</span>
                                  <span class="top2" v-if="index === 1">2</span>
                                  <span class="top3" v-if="index === 2">3</span>
                                  <a :href="row.source_url" target="_blank" class="title">{{ row.title }}</a>
                                  <a class="site"><span>|</span>{{ row.media }}</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="cont">
                        <h5>{{ row.state }}</h5>
                      </div>
                      <div class="timeline">
                        <div class="timeline-content">
                          <div class="circle">
                            <div class="mod-list">
                              <img src="/static/img/sheng_l.svg" alt="胜" class="img" width="25" v-if="row.weight_neg > row.weight_pos" />
                              <ul>
                                <li v-for="(row, index) in row.articles_neg" :key="index">
                                  <span class="top1" v-if="index === 0">1</span>
                                  <span class="top2" v-if="index === 1">2</span>
                                  <span class="top3" v-if="index === 2">3</span>
                                  <a :href="row.source_url" target="_blank" class="title">{{ row.title }}</a>
                                  <a class="site"><span>|</span>{{ row.media }}</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-card>
          </div>
          <div class="warpper pt-20">
            <el-card class="box-card">
              <h3 class="mod-title">负面信息关键字趋势变化
                <el-tooltip class="item" effect="dark" placement="bottom">
                  <div slot="content">时间段内，负面信息中的关键词随<br>时间变化的趋势</div>
                  <i class="iconfont icon-tishi"></i>
                </el-tooltip>
              </h3>
              <div class="pad-lr-20">
                <div class="echarts_empty" v-show="data['neg_key_tre'].keys.length < 1">
                  <div class="img"></div>
                </div>
                <div class="w-100 h-380" id="neg_key_tre" v-show="data['neg_key_tre'].keys.length >= 1"></div>
              </div>
            </el-card>
          </div>
          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card echarts-box">
                  <h3 class="mod-title">网媒负面信息热门关键词
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">对网媒负面信息进行分词及重要关键词词频<br>统计，展示词频最高的50个重要关键词</div>
                      <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data.neg_key_for_web_media.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="w-100 h-100 ov-h" v-show="data.neg_key_for_web_media.length >= 1">
                      <div class="mod-list">
                        <ul>
                          <li v-for="(data, index) in data.neg_key_for_web_media" :key="index" v-if="index <= 9">
                            <span class="num bg-555" v-if="index == 0">1</span>
                            <span class="num bg-689" v-if="index == 1">2</span>
                            <span class="num bg-8ea" v-if="index == 2">3</span>
                            <span class="num" v-if="index >= 3">{{ index + 1 }}</span>
                            <a class="mod-list-title1" :title="data.keyword">{{ data.keyword }}</a>
                          </li>
                        </ul>
                      </div>
                      <div class="gjcy mod-echarts-right h-350" id="neg_key_for_web_media"></div>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card echarts-box">
                  <h3 class="mod-title">微博负面信息热门关键词
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">对微博负面信息进行分词及重要关键词词频<br>统计，展示词频最高的50个重要关键词</div>
                      <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data.neg_key_for_weibo.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="w-100 h-100 ov-h" v-show="data.neg_key_for_weibo.length >= 1">
                      <div class="mod-list">
                        <ul>
                          <li v-for="(data, index) in data.neg_key_for_weibo" :key="index" v-if="index <= 9">
                            <span class="num bg-555" v-if="index == 0">1</span>
                            <span class="num bg-689" v-if="index == 1">2</span>
                            <span class="num bg-8ea" v-if="index == 2">3</span>
                            <span class="num" v-if="index >= 3">{{ index + 1 }}</span>
                            <a class="mod-list-title1" :title="data.keyword">{{ data.keyword }}</a>
                          </li>
                        </ul>
                      </div>
                      <div class="gjcy mod-echarts-right h-350" id="neg_key_for_weibo"></div>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <div class="warpper pt-20">
            <el-row :gutter="20" class="el-row-20">
              <el-col :span="12">
                <el-card class="box-card echarts-box">
                  <h3 class="mod-title">微信负面信息热门关键词
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">对微信负面信息进行分词及重要关键词词频<br>统计，展示词频最高的50个重要关键词</div>
                      <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data.neg_key_for_weixin.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="w-100 h-100 ov-h" v-show="data.neg_key_for_weixin.length >= 1">
                      <div class="mod-list h-360">
                        <ul>
                          <li v-for="(data, index) in data.neg_key_for_weixin" :key="index" v-if="index <= 9">
                            <span class="num bg-555" v-if="index == 0">1</span>
                            <span class="num bg-689" v-if="index == 1">2</span>
                            <span class="num bg-8ea" v-if="index == 2">3</span>
                            <span class="num" v-if="index >= 3">{{ index + 1 }}</span>
                            <a class="mod-list-title1" :title="data.keyword">{{ data.keyword }}</a>
                          </li>
                        </ul>
                      </div>
                      <div class="gjcy mod-echarts-right h-350" id="neg_key_for_weixin"></div>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="12">
                <el-card class="box-card echarts-box">
                  <h3 class="mod-title">其他来源负面信息热门关键词
                    <el-tooltip class="item" effect="dark" placement="bottom">
                      <div slot="content">对其他来源负面信息进行分词及重要关键词<br>词频统计，展示词频最高的50个重要关键词</div>
                      <i class="iconfont icon-tishi"></i>
                    </el-tooltip>
                  </h3>
                  <div class="pad-lr-20">
                    <div class="echarts_empty" v-show="data.neg_key_for_forum.length < 1">
                      <div class="img"></div>
                    </div>
                    <div class="w-100 h-100 ov-h" v-show="data.neg_key_for_forum.length >= 1">
                      <div class="mod-list">
                        <ul>
                          <li v-for="(data, index) in data.neg_key_for_forum" :key="index" v-if="index <= 9">
                            <span class="num bg-555" v-if="index == 0">1</span>
                            <span class="num bg-689" v-if="index == 1">2</span>
                            <span class="num bg-8ea" v-if="index == 2">3</span>
                            <span class="num" v-if="index >= 3">{{ index + 1 }}</span>
                            <a class="mod-list-title1" :title="data.keyword">{{ data.keyword }}</a>
                          </li>
                        </ul>
                      </div>
                      <div class="gjcy mod-echarts-right h-350" id="neg_key_for_forum"></div>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </el-container>
</template>
<script type="application/javascript">
  import { findPointImg, globalCreateChart, globalGetEcharts, globalMediaColor } from "@/utils/helpers.js"
  import { detail } from "@/api/analysis";
  export default {
    name: "analysis-emotion",
    data() {
      return {
        title: "",
        data: {},
        calendarMonth: {left: {month: null, data: []}, right: {month: null, data: []}},
        colors: ['#8ABAF8', '#E9A142', '#599E65', '#4478D2'], //網媒
        loading: true,
        created: ""
      }
    },
    mounted() {
      this.loadData()
      this.$nextTick(() => {
        let _this = this
        window.onscroll = function () {
          let height = document.documentElement.scrollTop + 75
          if (height > 200) {
            $("#emotion-tab").addClass("scroll-tab")
            $("#emotion-tab").addClass("scroll")
          } else {
            $("#emotion-tab").removeClass("scroll")
            $("#emotion-tab").removeClass("scroll-tab")
          }
          let b = window.document.getElementById("lyfx").offsetTop
          let c = window.document.getElementById("qsfx").offsetTop
          let d = window.document.getElementById("fmxxpx").offsetTop
          if (height < b) {
            _this.switchTab(0)
          } else if (height >= b && height < c) {
            _this.switchTab(1)
          } else if (height >= c && height < d) {
            _this.switchTab(2)
          } else {
            _this.switchTab(3)
          }
        }
      })
    },
    methods:{
      findPointImg(name) {
        return findPointImg(name)
      },
      isArray(data) {
        return _.isArray(data)
      },
      switchTab(index, scrollTo = false) {
        if (scrollTo) {
          var h = 0
          switch (index) {
            case 0:
              document.documentElement.scrollTop = h
              break;
            case 1:
              h = document.documentElement.scrollTop === 0 ? 140 : 75;
              document.documentElement.scrollTop = window.document.getElementById("lyfx").offsetTop - h
              break;
            case 2:
              h = document.documentElement.scrollTop === 0 ? 140 : 75;
              document.documentElement.scrollTop = window.document.getElementById("qsfx").offsetTop - h
              break;
            case 3:
              h = document.documentElement.scrollTop === 0 ? 140 : 75;
              document.documentElement.scrollTop = window.document.getElementById("fmxxpx").offsetTop - h
              break;
          }
        }
        $(".flex-container a").removeClass("selected")
        $(".flex-container a:eq("+ index +")").addClass("selected")
      },
      loadData() {
        detail({id: this.$route.query.id}).then(res => {
          if (res.data.state) {
            this.data = JSON.parse(res.data.data.result)
            const { title, updated, subtitle, theme_color } = res.data.data
            this.title = title;
            this.subtitle = subtitle;
            this.theme_color = theme_color;
            this.created = updated;
            this.loading = false
            this.handleData()
            this.$nextTick(() => {
              this.createChart()
            })
          } else {
            this.$message.error(res.data.error)
          }
        }).catch(err => {
          window.console.error(err)
          this.$message.error("加载数据失败，服务失败")
        })
      },
      handleData() {
        var _this = this
        this.data.neg_num_top = this.data.neg_num_top.map(function (item, index) {
          item.rate = (item.total / _this.data.neg_num_top[0].total).toFixed(2) * 100
          return item
        })
        this.data.neg_tre_date.range = this.data.neg_tre_date.range.map((v, k) => {
          let ymd = v.split("-")
          let maxDay = new Date(ymd[0], ymd[1], 0).getDate()
          return k == 0 ? ymd[0] + "-" + ymd[1] + "-" + "01" : ymd[0] + "-" + ymd[1] + "-" + maxDay
        })
        if (this.data.pos_vs_neg.isIncubation) {
          let posWeight = 0
          let negWeight = 0
          this.data.pos_vs_neg.timeline.map((item) => {
            posWeight += parseInt(item['weight_pos'] * 100)
            negWeight += parseInt(item['weight_neg'] * 100)
          })
          this.data.pos_vs_neg.pos_weight = parseInt(posWeight / (posWeight + negWeight) * 100)
          this.data.pos_vs_neg.neg_weight = parseInt(negWeight / (posWeight + negWeight) * 100)
          this.data.pos_vs_neg.pos_neg_diff = Math.abs(this.data.pos_vs_neg.neg_weight - this.data.pos_vs_neg.pos_weight)
        }
      },
      createChart() {
        let loopKeys = ['em_por', 'neg_dis_for_cat', 'neg_dis_for_web_media', 'neg_dis_for_weibo_type', 'neg_dis_for_weibo_user']
        let lineKeys = ['em_dis_for_cat', 'em_dis_for_web_media', 'em_dis_for_weibo_type', 'em_dis_for_weibo_user']
        let trendKeys = ['em_tre', 'em_tre_for_web_media', 'em_tre_for_weibo', 'em_tre_for_weixin', 'neg_tre', 'neg_tre_for_web_media', 'neg_tre_for_weibo_type', 'neg_tre_for_weibo_user']
        let wordsKeys = ['neg_key', 'neg_key_for_web_media', 'neg_key_for_weibo', 'neg_key_for_weixin', 'neg_key_for_forum']
        let data = this.data
        for (let key in data) {
          if (loopKeys.indexOf(key) !== -1) {
            this.loopChart(data[key], key)
          } else if (lineKeys.indexOf(key) !== -1) {
            this.lineRateChart(data[key], key)
          } else if (trendKeys.indexOf(key) !== -1) {
            this.trendChart(data[key], key)
          } else if (wordsKeys.indexOf(key) !== -1) {
            this.wordCloudChart(data[key], key)
          }
        }
        // 整体信息情感日历 第一月
        var args = {month: data['em_por_date'].type[0], data: data['em_por_date'].data}
        this.calendarChart(args, 'em_por_date')
        this.pointChart(data['em_dis_for_media'], 'em_dis_for_media')
        this.pointChart(data['em_dis_for_media_por'],'em_dis_for_media_por')
        this.pointCalendarChart(data['neg_tre_date'])
        this.wordHeatChart(data['neg_key_tre'])
        if (this.data.pos_vs_neg.isIncubation) {
          this.vsTrend()
        }
      },
      vsTrend() {
        // pos_vs_neg_trend
        let data = this.data["pos_vs_neg"].trend
        let keys = []
        let seriesData = []
        data.map((item) => {
          keys.push(item.date)
          if (item.points) {
            seriesData.push({
              value: item.count,
              symbol: 'image://' + findPointImg(item.points[0]),
              name: item.points.join(",")
            })
          } else {
            seriesData.push({
              value: item.count,
              name: ""
            })
          }
        })
        let option = {
          tooltip : {
            trigger: 'axis'
          },
          calculable : true,
          xAxis : [
            {
              type : 'category',
              boundaryGap : false,
              data : keys
            }
          ],
          yAxis : [
            {
              type : 'value'
            }
          ],
          series : [
            {
              type:'line',
              stack: '总量',
              symbol: 'none',
              symbolSize:20,
              label: {
                normal: {
                  show: true,
                  position: 'top',
                  formatter: function (params) {
                    return params.data.name
                  }
                },
              },
              data: seriesData
            }
          ]
        };
        let element = window.document.getElementById("pos_vs_neg_trend")
        let chart = globalCreateChart(element, option)
        window.addEventListener("resize", () => { chart.resize();})
      },
      loopChart(data, id) {
        var name = null
        var legend = []
        var chartData = []
        var colors = ["#3479CF","#55D9FF","#74CCB1","#3178d2"] //網媒
        var rateMap = []
        switch (id) {
          case 'em_por':
            name = "情感分布"
            legend = ['负面', '正面']
            colors = ['#FC5D73','#555DFE']
            chartData = [{name: "负面", value: data.negative}, {name: "正面", value: data.positive}]
            var total = parseInt(data.negative) + parseInt(data.positive)
            rateMap = [(data.negative / total * 100).toFixed(2), (data.positive / total * 100).toFixed(2)]
            break;
          case  'neg_dis_for_cat':
            name = "媒体类型分布"
            legend = data.type
            var total = 0
            for (let i = 0; i < legend.length; i++) {
              total += data.data[i]
              let item = {name: legend[i], value: data.data[i], itemStyle: {}}
              let color = globalMediaColor(legend[i])
              if (color) item.itemStyle.color = color
              chartData.push(item)
            }
            for (let i = 0; i < data.data.length; i++) {
              rateMap.push((data.data[i] / total * 100).toFixed(2))
            }
            break;
          case 'neg_dis_for_web_media':
            name = "网媒类型分布"
            legend = data.type
            var total = 0
            for (let i = 0; i < legend.length; i++) {
              total += data.data[i]
              chartData.push({name: legend[i], value: data.data[i]})
            }
            for (let i = 0; i < data.data.length; i++) {
              rateMap.push((data.data[i] / total * 100).toFixed(2))
            }
            break;
          case 'neg_dis_for_weibo_type':
            name = "微博类型分布"
            legend = data.type
            colors = ['#ffa237', '#009df6']
            chartData = [ {name: data.type[0], value: data.data[0]},{name: data.type[1], value: data.data[1]}]
            var total = parseInt(data.data[0]) + parseInt(data.data[1])
            rateMap = [(data.data[0] / total * 100).toFixed(2), (data.data[1] / total * 100).toFixed(2)]
            break;
          case 'neg_dis_for_weibo_user':
            name = "微博用户类型分布"
            colors = []
            legend = data.type
            var total = 0
            for (let i = 0; i < legend.length; i++) {
              total += data.data[i]
              chartData.push({name: legend[i], value: data.data[i]})
            }
            for (let i = 0; i < data.data.length; i++) {
              rateMap.push((data.data[i] / total * 100).toFixed(2))
            }
            legend = legend.map((item, index) => {
              let temp = {
                name: item,
                textStyle:{
                  color:'#ffa237'
                },
                itemGap:20,
                itemWidth:10,
                itemHeight:10,
                icon:'image:///static/img/v_red.png'
              }
              switch (item) {
                case "金V":
                  colors.push('#c91d10');
                  temp.textStyle.color = '#c91d10';
                  temp.icon = 'image:///static/img/v_red.png';
                  break;
                case "黄V":
                  colors.push('#fea700');
                  temp.textStyle.color = '#fea700';
                  temp.icon = 'image:///static/img/v_orange.png';
                  break;
                case "蓝V":
                  colors.push('#09aaf7');
                  temp.textStyle.color = '#09aaf7';
                  temp.icon = 'image:///static/img/v_blue.png';
                  break;
                case "微博女郎":
                  colors.push('#fc5d9a');
                  temp.textStyle.color = '#fc5d9a';
                  temp.icon = 'image:///static/img/v_pink.png';
                  break;
                case "达人":
                  colors.push('#e8220e');
                  temp.textStyle.color = '#e8220e';
                  temp.icon = 'image:///static/img/v_talent_show.png';
                  break;
                default:
                  colors.push('#0078d5');
                  temp.textStyle.color = '#0078d5';
                  temp.icon = 'circle';
                  break;
              }
              return temp;
            })
            break;
        }
        let option = {
          tooltip: {
            trigger: 'item',
            formatter: "{a} <br/>{b}: {c}({d}%)"
          },
          legend: {
            itemWidth:10,
            itemHeight:10,
            orient: 'vertical',
            left: '10px',
            bottom: "20px",
            data: legend,
            icon: "circle",
            itemGap:5,
            formatter: function (name) {
              let legendMap = [];
              _(legend).forEach((item, index) => {
                if (typeof item == "string") {
                  legendMap.push(item)
                } else if (typeof item == "object") {
                  legendMap.push(item.name)
                }
              })
              let index = legendMap.indexOf(name);
              return isNaN(rateMap[index]) ? name + "0%" : name + " " + rateMap[index] + '%';
            }
          },
          color: colors,
          series: [
            {
              name:name,
              type:'pie',
              center:['50%', '50%'],
              radius: ['45%', '60%'],
              avoidLabelOverlap: false,
              label: {
                normal: {
                  show: false,
                  position: 'center'
                },
                emphasis: {
                  show: true,
                  textStyle: {
                    fontSize: '16',
                    fontWeight: 'bold'
                  }
                }
              },
              labelLine: {
                normal: {
                  show: false
                }
              },
              data: chartData
            }
          ]
        }
        let element = window.document.getElementById(id)
        let chart = globalCreateChart(element, option)
        window.addEventListener("resize", () => { chart.resize();})
      },
      lineRateChart(data, id) {
        let negativeData = []
        let positiveData = []
        for(let i = 0; i < data.data.length; i++) {
          negativeData.push(data.data[i].negative)
          positiveData.push(data.data[i].positive)
        }
        let option = {
          tooltip : {
            trigger: 'axis',
            axisPointer : {            // 坐标轴指示器，坐标轴触发有效
              type : 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
            },
            formatter: "{b} <br />{a}：{c}%<br />{a1}：{c1}%"
          },
          legend: {
            data: ['负面','正面'],
            icon: "circle",
            itemGap:20,
            itemWidth:10,
            itemHeight:10,
            selectedMode: false
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '10px',
            top:"40px",
            containLabel: true
          },
          xAxis:  {
            type: 'value',
            axisLabel: {
              show: true,
              interval: 'auto',
              formatter: '{value}%'
            }
          },
          yAxis: {
            type: 'category',
            data: data.type
          },
          series: [
            {
              name: '负面',
              type: 'bar',
              stack: '总量',
              itemStyle: {
                normal: {
                  color: "#FC5D73",
                  barBorderRadius:[10, 0, 0, 10]
                }
              },
              data: negativeData
            },
            {
              name: '正面',
              type: 'bar',
              stack: '总量',
              barWidth: 10,
              itemStyle: {
                normal: {
                  color: "#555DFE",
                  barBorderRadius:[0, 10, 10, 0]
                }
              },
              data: positiveData
            }
          ]
        }
        let element = window.document.getElementById(id)
        let chart = globalCreateChart(element, option)
        window.addEventListener("resize", () => { chart.resize();})
      },
      calendarChart(data, id) {
        // data = {month: 2018-12-07, data: []}
        let echarts = globalGetEcharts()
        this.calculationMonth(data.month)
        this.$nextTick(() => {
          var cellSize = [35, 35];
          var pieRadius = 7;
          function getPieSeries(scatterData, chart) {
            return echarts.util.map(scatterData, function (item, index) {
              let temp = [item.date, item.total]
              var center = chart.convertToPixel('calendar', temp)
              return {
                id: index + 'pie',
                type: 'pie',
                center: center,
                label: {
                  normal: {
                    formatter: '{c}',
                    position: 'inside',
                    fontSize: 9,
                    show: false
                  }
                },
                radius: pieRadius,
                data: [
                  {name: '正面', value: item.positive},
                  {name: '负面', value: item.negative}
                ]
              }
            })
          }
          let scatterData = data.data.map(function (item, index) {
            return [item.date, item.total]
          })
          let option = {
            tooltip : {
              formatter: "{b}：{c}%"
            },
            legend: {
              data: ['正面', '负面'],
              bottom: 0,
              icon: "circle",
              itemGap:20,
              itemWidth:10,
              itemHeight:10,
              show: false
            },
            calendar: {
              top: 'middle',
              left: 'center',
              orient: 'vertical',
              cellSize: cellSize,
              yearLabel: {
                show: false,
                textStyle: {
                  fontSize: 30
                }
              },
              dayLabel: {
                margin: 0,
                firstDay: 1,
                nameMap: ['日', '一', '二', '三', '四', '五', '六']
              },
              monthLabel: {
                show: false
              },
              range: [data.month]
            },
            color: ["#555DFE", "#FC5D73"],
            series: [{
              id: 'label',
              type: 'scatter',
              coordinateSystem: 'calendar',
              symbolSize: 1,
              label: {
                normal: {
                  show: true,
                  formatter: function (params) {
                    return echarts.format.formatTime('dd', params.value[0]);
                  },
                  offset: [-cellSize[0] / 2 + 10, -cellSize[1] / 2 + 10],
                  textStyle: {
                    color: '#000',
                    fontSize: 9
                  }
                }
              },
              data: scatterData
            }]
          };
          let element = window.document.getElementById(id)
          var chart = globalCreateChart(element, option, true)
          chart.setOption({series: getPieSeries(data.data, chart)})
        })
      },
      pointChart(data, id) {
        let option = {
          tooltip : {
            trigger: 'item'
          },
          xAxis: {name: '正面信息数', bottom:"15px", splitLine: {show: false}},
          yAxis: {name: '负面信息数', splitLine: {show: false}},
          grid:{right: '80px',bottom:"30px"},
          legend: {
            itemWidth:10,
            itemHeight:10,
            itemGap:20,
            data: data.type
          },
          color: this.colors,
          series: []
        };
        if (id == 'em_dis_for_media_por') {
          option.xAxis.axisLabel = {
            show: true,
            interval: 'auto',
            formatter: '{value} %'
          }
          option.yAxis.axisLabel = {
            show: true,
            interval: 'auto',
            formatter: '{value} %'
          }
          option.tooltip.formatter = function (params) {
            return params.seriesName + "：" + params.value[2] + "<br />正面：" + params.value[0] + "%" + "<br />负面：" + params.value[1] + "%"
          }
        } else {
          option.tooltip.formatter = function (params) {
            return params.seriesName + "：" + params.value[2] + "<br />正面：" + params.value[0] + "<br />负面：" + params.value[1]
          }
        }
        let seriesData = []
        for (let i = 0; i < data.data.length; i++) {
          let series = {
            name: data.type[i],
            symbolSize: 13,
            label: {
              emphasis: {
                show: true,
                formatter: function (param) {
                  return param.data[2];
                },
                position: 'top'
              }
            },
            itemStyle: {},
            data: [],
            type: 'scatter'
          }
          let color = globalMediaColor(data.type[i])
          if (color) series.itemStyle.color = color
          _(data.data[i]).forEach((item, index) => {
            item.value.push(item.name);
            series.data.push(item.value);
          })
          seriesData.push(series)
        }
        option.series = seriesData
        let element = window.document.getElementById(id)
        let chart = globalCreateChart(element, option)
      },
      trendChart(data, id) {
        let option = {
          tooltip : {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#6a7985'
              }
            }
          },
          legend: {
            top:"5px",
            itemWidth:10,
            itemHeight:10,
            itemGap:20,
            icon: "circle",
            itemGap:10,
            type: 'scroll',
            pageIconColor:"#555DFE"
          },
          grid: {
            left: '3%',
            right: '4%',
            top: '40px',
            bottom: '10px',
            width:"90%",
            containLabel: true
          },
          xAxis : {
            type : 'category',
            boundaryGap : false,
            data: data.keys
          },
          yAxis: {type : 'value'},
          dataZoom: [
            {
              type: 'inside',
              start: 0,
              end: 100
            }
          ],
          color: ["#ed3815","#ffa237 ","#2fb0f8 ","#bc1718 ","#fd4e91","0279d6"],
          series : []
        }
        let npKeys = ['em_tre', 'em_tre_for_web_media', 'em_tre_for_weibo', 'em_tre_for_weixin'] // 正负面数据类型
        let nkeys = ['neg_tre', 'neg_tre_for_web_media', 'neg_tre_for_weibo_user'] // 负面数据媒体类型
        let weiboKey = 'neg_tre_for_weibo_type' // 负面数据微博 转发 : 原发
        let seriesData = []
        let echarts = globalGetEcharts()
        var _this = this
        if (npKeys.indexOf(id) !== -1) {
          seriesData.push({
            name: "负面",
            type: 'line',
            smooth: true,
            color: '#FC5D73',
            areaStyle: {
             normal: {
             color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                          offset: 0,
                          color: '#FC5D73'
                      }, {
                          offset: 1,
                          color: '#fff'
                      }])
                  }
              },
            data: data.negative
          })
          seriesData.push({
            name: "正面",
            type: 'line',
            color: '#555DFE',
            smooth: true,
            areaStyle: {
             normal: {
             color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                          offset: 0,
                          color: '#555DFE'
                      }, {
                          offset: 1,
                          color: '#fff'
                      }])
                  }
              },
            data: data.positive
          })
        } else if (nkeys.indexOf(id) !== -1) {
          _(data.data).forEach((item, index) => {
            item.type = 'line';
            item.smooth = true;
            item.areaStyle = {normal: {}};
            if (id == "neg_tre_for_weibo_user") {
              switch (item.name) {
                case "金V":
                  item.color = '#c91d10'
                  item.areaStyle = {
                    normal: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: '#c91d10'
                      }, {
                        offset: 1,
                        color: '#fff'
                      }])
                    }
                  }
                  break
                case "黄V":
                  item.color = '#fea700'
                  item.areaStyle = {
                    normal: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: '#fea700'
                      }, {
                        offset: 1,
                        color: '#fff'
                      }])
                    }
                  }
                  break
                case "蓝V":
                  item.color = '#09aaf7'
                  item.areaStyle = {
                    normal: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: '#09aaf7'
                      }, {
                        offset: 1,
                        color: '#fff'
                      }])
                    }
                  }
                  break
                case "微博女郎":
                  item.color = '#fc5d9a'
                  item.areaStyle = {
                    normal: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: '#fc5d9a'
                      }, {
                        offset: 1,
                        color: '#fff'
                      }])
                    }
                  }
                  break
                case "达人":
                  item.color = '#e8220e'
                  item.areaStyle = {
                    normal: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: '#e8220e'
                      }, {
                        offset: 1,
                        color: '#fff'
                      }])
                    }
                  }
                  break
                default:
                  item.color = '#0078d5'
                  item.areaStyle = {
                    normal: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: '#0078d5'
                      }, {
                        offset: 1,
                        color: '#fff'
                      }])
                    }
                  }
                  break
              }
            } else if (id == "neg_tre") {
              let color = globalMediaColor(item.name)
              if (color) {
                item.color = color
                item.areaStyle = {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                      offset: 0,
                      color: color
                    }, {
                      offset: 1,
                      color: '#fff'
                    }])
                  }
                }
              } else {
                if (index < _this.colors.length) {
                  item.color = _this.colors[index]
                  item.areaStyle = {
                    normal: {
                      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: item.color
                      }, {
                        offset: 1,
                        color: '#fff'
                      }])
                    }
                  }
                }
              }
            } else {
              if (index < _this.colors.length) {
                item.color = _this.colors[index]
                item.areaStyle = {
                  normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                      offset: 0,
                      color: item.color
                    }, {
                      offset: 1,
                      color: '#fff'
                    }])
                  }
                }
              }
            }
            seriesData.push(item)
          })
          if (id == "neg_tre_for_weibo_user") {
            let legend = data.type.map((type, index) => {
              let temp = {
                name: type,
                textStyle:{
                  color:'#ffa237'
                },
                itemGap:20,
                icon:'image:///static/img/v_red.png'
              }
              switch (type) {
                case "金V":
                  temp.textStyle.color = '#c91d10'
                  temp.icon = 'image:///static/img/v_red.png'
                  break
                case "黄V":
                  temp.textStyle.color = '#fea700'
                  temp.icon = 'image:///static/img/v_orange.png'
                  break
                case "蓝V":
                  temp.textStyle.color = '#09aaf7'
                  temp.icon = 'image:///static/img/v_blue.png'
                  break
                case "微博女郎":
                  temp.textStyle.color = '#fc5d9a'
                  temp.icon = 'image:///static/img/v_pink.png'
                  break
                case "达人":
                  temp.textStyle.color = '#e8220e'
                  temp.icon = 'image:///static/img/v_talent_show.png'
                  break
                default:
                  temp.textStyle.color = '#0078d5'
                  temp.icon = 'circle'
                  break
              }
              return temp
            })
            option.legend.data = legend
            option.legend.itemWidth = 10
            option.legend.itemHeight = 10
          }
        } else if (id == weiboKey) {
          seriesData.push({
            name: "原发",
            type: 'line',
            color: '#ffa237',
            smooth: true,
            areaStyle: {
             normal: {
             color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: '#ffa237'
                    }, {
                        offset: 1,
                        color: '#fff'
                    }])
                }
            },
            data: data.forward
          })
          seriesData.push({
            name: "转发",
            type: 'line',
            smooth: true,
            color: '#009df6',
            areaStyle: {
             normal: {
             color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                        offset: 0,
                        color: '#009df6'
                    }, {
                        offset: 1,
                        color: '#fff'
                    }])
                }
            },
            data: data.original
          })
        }
        option.series = seriesData
        let element = window.document.getElementById(id)
        let chart = globalCreateChart(element, option)
      },
      pointCalendarChart(data) {
        let chartData = data.data
        let top10Data = chartData.sort(function (a, b) {
          return b[1] - a[1];
        }).slice(0, 10)
        let option = {
          backgroundColor: '#fff',
          tooltip : {
            trigger: 'item'
          },
          legend: {
            top: '30',
            itemWidth:10,
            itemHeight:10,
            itemGap:20,
            data:['负面信息数', 'Top 10']
          },
          calendar: [],
          series: []
        };
        let range = data.range
        let minYear = range[0].split('-')[0]
        let maxYear = range[1].split('-')[0]
        if (minYear != maxYear) {
          let date = new Date(minYear, 12, 0)
          let minRange = [range[0], minYear + '-12-' + date.getDate()]
          let maxRange = [maxYear + '-01-01', range[1]]
          option.calendar.push({
            top: 100,
            left: 'center',
            range: minRange,
            splitLine: {
              show: true,
              lineStyle: {
                color: '#000',
                width: 4,
                type: 'solid'
              }
            },
            yearLabel: {
              formatter: '{start}  1st',
              textStyle: {
                color: '#fff'
              }
            },
            itemStyle: {
              normal: {
                color: '#fff',
                borderWidth: 1,
                borderColor: '#111'
              }
            }
          })
          option.calendar.push({
            top: 340,
            left: 'center',
            range: maxRange,
            splitLine: {
              show: true,
              lineStyle: {
                color: '#000',
                width: 4,
                type: 'solid'
              }
            },
            yearLabel: {
              formatter: '{start}  2nd',
              textStyle: {
                color: '#fff'
              }
            },
            itemStyle: {
              normal: {
                color: '#fff',
                borderWidth: 1,
                borderColor: '#111'
              }
            }
          })
          option.series = [
            {
              name: '负面信息数',
              type: 'scatter',
              coordinateSystem: 'calendar',
              data: chartData,
              symbolSize: function (val) {
                return (val[1] / top10Data[0][1]).toFixed(2) * 20;
              },
              itemStyle: {
                normal: {
                  color: 'red'
                }
              }
            },
            {
              name: '负面信息数',
              type: 'scatter',
              coordinateSystem: 'calendar',
              calendarIndex: 1,
              data: chartData,
              symbolSize: function (val) {
                return (val[1] / top10Data[0][1]).toFixed(2) * 20;
              },
              itemStyle: {
                normal: {
                  color: 'red'
                }
              }
            },
            {
              name: 'Top 10',
              type: 'effectScatter',
              coordinateSystem: 'calendar',
              calendarIndex: 1,
              data: top10Data,
              symbolSize: 20,
              showEffectOn: 'render',
              rippleEffect: {
                brushType: 'stroke'
              },
              hoverAnimation: true,
              itemStyle: {
                normal: {
                  color: '#f4e925',
                  shadowBlur: 10,
                  shadowColor: '#333'
                }
              },
              zlevel: 1
            },
            {
              name: 'Top 10',
              type: 'effectScatter',
              coordinateSystem: 'calendar',
              data: top10Data,
              symbolSize: 20,
              showEffectOn: 'render',
              rippleEffect: {
                brushType: 'stroke'
              },
              hoverAnimation: true,
              itemStyle: {
                normal: {
                  color: '#f4e925',
                  shadowBlur: 10,
                  shadowColor: '#333'
                }
              },
              zlevel: 1
            }
          ]
        } else {
          option.calendar.push({
            top: 230,
            left: 'center',
            range: range,
            splitLine: {
              show: true,
              lineStyle: {
                color: '#000',
                width: 4,
                type: 'solid'
              }
            },
            yearLabel: {
              formatter: '{start}  1st',
              textStyle: {
                color: '#fff'
              }
            },
            itemStyle: {
              normal: {
                color: '#fff',
                borderWidth: 1,
                borderColor: '#111'
              }
            }
          })
          option.series = [
            {
              name: '负面信息数',
              type: 'scatter',
              coordinateSystem: 'calendar',
              data: chartData,
              symbolSize: function (val) {
                return (val[1] / top10Data[0][1]).toFixed(2) * 20;
              },
              itemStyle: {
                normal: {
                  color: 'red'
                }
              }
            },
            {
              name: 'Top 10',
              type: 'effectScatter',
              coordinateSystem: 'calendar',
              data: top10Data,
              symbolSize: 20,
              showEffectOn: 'render',
              rippleEffect: {
                brushType: 'stroke'
              },
              hoverAnimation: true,
              itemStyle: {
                normal: {
                  color: '#f4e925',
                  shadowBlur: 10,
                  shadowColor: '#333'
                }
              },
              zlevel: 1
            }
          ]
        }
        let element = window.document.getElementById('neg_tre_date')
        var chart = globalCreateChart(element, option)
      },
      wordHeatChart(data) {
        var max = 0;
        _(data.data).forEach((row, index) => {
          row.map((v, k) => {
            max = v.total > max ? v.total : max
          })
        })
        let option = {
          tooltip: {
            position: 'top',
            formatter: function (params) {
              return params.seriesName + "：" + params.data[2]
            }
          },
          animation: false,
          grid: {
            bottom: "10px",
            left: "130px"
          },
          xAxis: {
            type: 'category',
            data: [],
            splitArea: {
              show: true
            },
            show: false
          },
          yAxis: {
            type: 'category',
            data: data.keys.map((v,k) => {
              return v.split(" ")[0] + " " + data.desc[k]
            }),
            splitArea: {
              show: true
            }
          },
          visualMap: {
            min: 0,
            max: max,
            calculable: true,
            orient: 'horizontal',
            show: false,
            inRange: {
               color: ['#ffbeb2','#EE000F']
           }
          },
          series: []
        };
        let seriesData = [];
        _(data.data).forEach((item, index) => {
          _(item).forEach((sub_item, sub_index) => {
            if (sub_index > 19) return;
            let temp = {
              name: sub_item.keyword,
              type: 'heatmap',
              data: [[sub_index, index, sub_item.total]],
              label: {
                normal: {
                  show: true,
                  formatter: sub_item.keyword
                }
              }
            }
            seriesData.push(temp)
          })
        })
        option.series = seriesData
        let element = window.document.getElementById('neg_key_tre')
        var chart = globalCreateChart(element, option)
      },
      wordCloudChart(data, id) {
        let option = {
          tooltip: {
            trigger: 'item'
          },
          series: [{
            name: '信息数',
            type: 'wordCloud',
            shape: 'circle',
            top: '10%',
            width: '100%',
            height: '85%',
            right: null,
            bottom: null,
            sizeRange: [12, 30],
            rotationRange: [-90, 90],
            rotationStep: 45,
            hape: 'pentagon',
            gridSize: 8,
            drawOutOfBound: false,
            textStyle: {
              normal: {
                fontFamily: 'sans-serif',
                //fontWeight: 'bold',
                color: function (params) {
                  // Random color
                  let color = ['#4fcfb0', '#238fff', '#ff4f65', '#f98d3b', '#febd2e']
                  let index = Math.floor((Math.random()*color.length))
                  return color[index]
                }
              },
              emphasis: {
                shadowBlur: 10,
                shadowColor: '#333'
              }
            },
            data: []
          }]
        }
        let chartData = [];
        _(data).forEach((item, index) => {
          chartData.push({name: item.keyword, value: item.total});
        })
        option.series[0].data = chartData;
        let element = window.document.getElementById(id);
        var chart = globalCreateChart(element, option);
      },
      calculationMonth(month) {
        let data = this.data['em_por_date']
        let maxIndex = parseInt(this.data['em_por_date'].type.length - 1)
        if (data.type.length <= 1) {
          this.calendarMonth = {left: {month: null, data: []}, right: {month: null, data: []}}
          return false
        }
        for (let i = 0; i < data.type.length; i++) {
          if (data.type[i] == month) {
            // 最小区间
            if (i == 0) {
              this.calendarMonth = {left: {month: null, data: []}, right: {month: data.type[1], data: data.data}}
              break
            }
            // 最大区间
            if (i == maxIndex) {
              let index = i - 1
              this.calendarMonth = {left: {month: data.type[index], data: data.data}, right: {month: null, data: []}}
              break
            }
            let lt = i - 1
            let gt = i + 1
            this.calendarMonth = {left: {month: data.type[lt], data: data.data}, right: {month: data.type[gt], data: data.data}}
            break
          }
        }
      }
    }
  }
</script>
<style scoped>
/* 按钮组 */
.btn_tab {margin: auto;}
.btn_tab a {height: 44px;line-height: 44px;text-align: center;background: rgba(237, 237, 237, .6);color: rgba(0, 0, 0, .6);border-radius: 5px;display: block;padding: 0 73px;border: none;font-size: 14px;}
.btn_tab a.selected {color: #fff;background: rgba(42, 103, 179, 1);}
.fxbg.pt_0{padding-top: 0;}
/*flex-container */
.scroll-tab{position: fixed;z-index: 3000;left:auto;right:auto;top:200px;margin: auto;}
.scroll{top: 0px;}
.scroll .fxbg{box-shadow:0px 3px 13px 0px rgba(194,194,194,0.2);padding: 10px 0;}
.echarts_empty{height: 250px;}
/*负面信息内容观点*/
.fmxxnrgd .mod-list .mod-list-title1{width:356px;}
/*全部负面信息热门关键词*/
.gjcy{float: left;margin-top:16px;}
/*整体信息情感日历*/
.qgrl-chart{position: absolute; left: 120px; top: 0px;z-index: 10;width: calc(100% - 240px);}
.qgrl{width: 100%;position: relative;}
.qgrl .left,.qgrl .right{text-align: center;width:120px;line-height: 30px;position: absolute;z-index:10;top: 95px;}
.qgrl .left{left:0;}
.qgrl .right{right:0;}
.qgrl .el-icon-d-arrow-left,.qgrl .el-icon-d-arrow-right{font-size: 20px;cursor: pointer;}
.qgrl .el-icon-d-arrow-left:hover,.qgrl .el-icon-d-arrow-right:hover{color:#2a67b3;}
.logo h3{font-size: 30px;line-height: 60px;color:#1D77D2;}
/*时间轴*/
.demo{width:1080px;margin:20px auto;padding:45px 0 0;}
.main-timeline{position: relative;}
.main-timeline:before,.main-timeline:after{content: "";display: block;width: 100%;clear: both;}
.main-timeline:before{content: "";width: 3px;height:calc(100% - 50px);background:#bebebe;position: absolute;top: 0;left: 50%;}
.main-timeline .timeline{width: 50%;float: left;position: relative;z-index: 1;}
.main-timeline .timeline:before,.main-timeline .timeline:after{content: "";display: block;width: 100%;clear: both;}
.main-timeline .timeline-content{text-align: center;position: relative;transition: all 0.3s ease 0s;}
.main-timeline .timeline-content:before{content: "";width: 100%;height:3px;background:#bebebe;position: absolute;left: 0;z-index: -1;top:7px;}
.main-timeline .circle{width:450px;height:90px;background:rgb(239,244,254);border-radius: 8px;float: left;position: relative;color: #000;margin-top: -35px;}
.main-timeline .cont{display: table;padding:0 5px;position: relative;}
.main-timeline .year{display: block;margin:0 0 50px 0;border-radius: 7px;font-size:12px;color: #fff;width:80px;height: 19px;background-color: #2a67b3;border-radius: 4.5px;margin-top: -15px;}
.main-timeline .icon{width:16px;height:16px;border-radius: 50%;background: #fff;border:3px solid #2a67b3;position: absolute;top:0;right: -12px;display: inline-block;}
.main-timeline .timeline:first-child .timeline-content:before{top:7px;}
.main-timeline .timeline:nth-child(2n) .timeline-content,.main-timeline .timeline:nth-child(2n) .circle{float: right;}
.main-timeline .timeline:nth-child(even){margin-top:60px;}
.main-timeline .timeline:nth-child(even) .timeline-content:before{top:3px;}
.main-timeline .timeline:nth-child(even) .year{margin-top: -20px;}
.main-timeline .timeline:nth-child(even) .icon{left:-10px;right: 0;}
.main-timeline .mod-list{padding:8px 10px;}
.main-timeline .mod-list li{width:100%;line-height:25px;height:25px;text-align: left;}
.main-timeline .mod-list .num{margin:4px 10px 0 0;}
.main-timeline .mod-list li a{width: 390px;text-align: left;}
/*评分*/
.pk1,.pk2,.pk3{width:1120px;margin: 0 auto;height: 82px;overflow: hidden;/*background-image:url(/images/pk-01.png);*/background-repeat:no-repeat;background-size:100% 100%;-moz-background-size:100% 100%;line-height: 82px;color: #fff;text-align: center;}
.pk1{background-image:url(../../../../assets/images/analysis/vs-01.png);}
.pk2{background-image:url(../../../../assets/images/analysis/vs-02.png);}
.pk3{background-image:url(../../../../assets/images/analysis/vs-03.png);}
.pk1 p,.pk2 p,.pk3 p{float: left;font-weight:600;color:#fff;}
.pk1 .zm,.pk2 .fm{width:71%;}
.pk1 .fm,.pk2 .zm{width:29%;}
.pk3 .fm,.pk3 .zm{width:50%;}
/*时间轴*/
.demo2{width:1120px;margin: 0 auto;padding-top: 45px;}
.main-timeline2{ position: relative;padding-bottom:10px;}
.main-timeline2:after{content: "";display: block;width: 100%;clear: both;}
.main-timeline2 .timeline{width: 50%;float: left;position: relative;z-index: 1;margin-bottom: 20px;}
.main-timeline2 .timeline:before,.main-timeline2 .timeline:after{content: "";display: block;width: 100%;clear: both;}
.main-timeline2 .timeline-content{text-align: center;position: relative;transition: all 0.3s ease 0s;}
.main-timeline2 .timeline-content:before{content: "";width: 100%;height:3px;background:#bebebe;position: absolute;left: 0;z-index: -1;top:20px;}
.main-timeline2 .circle{width:480px;float: left;position: relative;color: #000;text-align: left;}
.main-timeline2 .timeline_title{background:rgb(239,244,254);border-radius: 8px;padding:11px 20px 11px 10px;line-height:25px;position: relative;}
.main-timeline2 .timeline_title .img,.main-timeline2 .mod-list .img{position: absolute;right:0;top: 0;z-index: 10;}
.main-timeline2 .timeline_title h4,.main-timeline2 .timeline_title p{overflow: hidden;white-space: nowrap;text-overflow:ellipsis;width: 433px;border: none;padding: 0;margin: 0;font-size: 12px;line-height: 24px;}
.main-timeline2 .timeline_title p span{padding-right: 10px;}
.main-timeline2 .timeline_title p .bd{padding-left: 10px;}
.main-timeline2 .timeline_title dl{overflow: hidden;margin-bottom: 0;}
.main-timeline2 .timeline_title dd,.main-timeline2 .timeline_title a,.main-timeline2 .timeline_title .bd{float:left;font-size: 12px;color: #474747;}
.main-timeline2 .timeline_title h4 a{float:none;}
.main-timeline2 .timeline_title .bd{padding: 0 15px;}
.main-timeline2 .timeline_title dd:last-child > .bd{display: none;}
.main-timeline2 .mod-list{padding:8px 20px 8px 10px;background:rgb(239,244,254);border-radius: 8px;margin-top:10px;position: relative;box-sizing: border-box;}
.main-timeline2 .mod-list li{width:100%;line-height:30px;height:30px;}
.main-timeline2 .num{margin-right: 10px;}
.main-timeline2 .mod-list li a{overflow: hidden;white-space: nowrap;text-overflow:ellipsis;float: left;}
.main-timeline2 .mod-list li .title{width: 320px;}
.main-timeline2 .mod-list li .site{width:80px;padding-left: 10px;color: #474747;float: left;}
.main-timeline2 .site span{padding-right: 10px;}
.main-timeline2 .cont{margin:0;display: table;padding:0 5px;position: absolute;top:0;left:500px;z-index: 10;text-align: center;width: 112px;}
.main-timeline2 .year{display: block;border-radius: 7px;font-size:14px;color: #fff;width:112px;height:40px;line-height:40px;background-color: #2a67b3;border-radius:8px;}
.main-timeline2 .point{height:60px;line-height: 20px;text-align: center;margin: 10px auto;overflow: hidden;font-size: 14px;font-weight:600;}
.main-timeline2 .point img{margin-right:8px;vertical-align: top;}
.main-timeline2 .point span{font-size: 14px;}
.main-timeline2 h5{width:36px;height:123px;background:linear-gradient(0deg,rgba(245,245,245,1) 0%,rgba(199,199,199,1) 50%,rgba(255,255,255,1) 100%);border-radius:18px;font-size: 20px;color:rgba(0,103,177,1);line-height:30px;text-align: center;padding-top:13px;margin:10px auto;margin-block-start:0;margin-block-end:0;}
.main-timeline2 .start{line-height: 19px;}
.main-timeline2 .timeline:nth-of-type(3),.main-timeline2 .timeline:nth-of-type(3) .circle{float: right;}
.demo2 .main-timeline2:last-child,.main-timeline2:last-child .timeline{margin: 0;}
.demo2 .item{position: relative;width:100%;overflow: hidden;}
.demo2 .main-timeline2 .item:nth-child(2n) .timeline-content:before{display: none;}
.item_list .timeline-content:before{display: none;}
.demo2 .main-timeline2:last-child{padding-bottom: 20px;}
</style>
